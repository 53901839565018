import request from '@/utils/request'

export async function getBiographiesPending(opts)
{
  opts = Object.assign({
    page: 1
  }, opts)

  const res = await request({
    url: `/contributions/artists/biography/pending?page=${opts.page}`,
    method: 'get'
  })

  return res.data.data
}

export async function getContributionBiography(id)
{
  const res = await request({
    url: `/contributions/biography/${id}`,
    method: 'get'
  })

  return res.data.data
}