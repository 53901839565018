<template>
  <div class="page-with-title">
    <page-title>
      <h1>Aprovação de biografias</h1>
    </page-title>
    <div class="p-4">
      <div class="row justify-content-center">
        <div class="col-lg-7">
          <table class="table table-dark">
            <thead>
              <tr>
                <th>ID</th>
                <th>Artista</th>
                <th>Data de envio</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="biography in biographies"
                :key="biography.id"
              >
                <td>{{ biography.id }}</td>
                <td>
                  <router-link :to="{name: 'biographyContributionShow', params: { id: biography.id }}">
                    {{ biography.artista }}
                  </router-link>
                </td>
                <td>{{ biography.created_at }}</td>
              </tr>
            </tbody>
          </table>
          <div class="text-center mb-4">
            <button-next-page
              v-if="lastPage >= page"
              @reached="fetchBiographies"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import PageTitle from '@/components/titles/PageTitle'
  import { getBiographiesPending } from '@/api/contributions'

  export default {
    components: { PageTitle },
    data: () => ({
      page: 1,
      biographies: [],
      lastPage: 1
    }),
    mounted () {
      this.fetchBiographies()
    },
    methods: {
      async fetchBiographies () {
        const res = await getBiographiesPending({ page: this.page++ })
        res.data.map(biography => this.biographies.push(biography))
        this.lastPage = res.data.last_page
      }
    }
  }
</script>